/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

const NavigationDots = ({ active }) => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="app__navigation">
      {["home", "about", "work", "skills", "testimonial", "contact"].map(
        (item, index) => (
          <a
            href={`#${item}`}
            aria-label="Section"
            key={item + index}
            className="app__navigation-dot"
            style={
              active === item
                ? { backgroundColor: darkMode ? "#b882ed" : "#313BAC" }
                : {}
            }
          />
        )
      )}
    </div>
  );
};
export default NavigationDots;
