import React, { useState, useEffect } from "react";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub";
import { AiFillEye } from "@react-icons/all-files/ai/AiFillEye";

import "./Work.scss";

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState("");
  const [visibleWorks, setVisibleWorks] = useState(4);

  useEffect(() => {
    let isMounted = true;

    const query = '*[_type == "works"] | order(publishedAt desc)';

    client.fetch(query).then((data) => {
      if (isMounted) {
        setFilterWork(data);
        setWorks(data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard("animate-exit");

    setTimeout(() => {
      setAnimateCard("animate-enter");

      if (item === "All") {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  const handleLoadMore = () => {
    setVisibleWorks(visibleWorks + 4);
  };

  return (
    <>
      <h2 className="head-text">
        My <span>Portfolio</span>
      </h2>

      <div className="app__work-filter">
        {["Web App", "Mobile App", "Web 3", "All"].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <div className={`app__work-portfolio ${animateCard}`}>
        {filterWork.slice(0, visibleWorks).map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <img
                src={urlFor(work.imgUrl) + "?w=238"}
                width={238}
                alt={work.title}
              />

              <div className="app__work-hover app__flex">
                {work.projectLink ? (
                  <a
                    href={work.projectLink}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Project-link"
                  >
                    <div className="app__flex">
                      <AiFillEye />
                    </div>
                  </a>
                ) : null}
                {work.codeLink ? (
                  <a
                    href={work.codeLink}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Code-link"
                  >
                    <div className="app__flex">
                      <AiFillGithub />
                    </div>
                  </a>
                ) : null}
              </div>
            </div>

            <div className="app__work-content app__flex">
              <h3 className="bold-text">{work.title}</h3>
              <p className="p-text" style={{ marginTop: 10 }}>
                {work.description}
              </p>

              <div className="app__work-tag app__flex">
                <p className="p-text">{work.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {visibleWorks < filterWork.length && (
        <div className="app__load-more">
          <button className="app__load-more-button" onClick={handleLoadMore}>
            Load More
          </button>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg"
);
