import "./Toggle.scss";

import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { FaSun } from "@react-icons/all-files/fa/FaSun";
import { FaMoon } from "@react-icons/all-files/fa/FaMoon";
const Toggle = () => {
  const theme = useContext(ThemeContext);

  const handleClick = () => {
    theme.dispatch({ type: "TOGGLE" });
  };
  return (
    <>
      <input type="checkbox" className="checkbox" id="checkbox" />
      <label
        htmlFor="checkbox"
        className="label"
        style={{
          backgroundColor: theme.state.darkMode ? "#258EE5" : "#313bac",
        }}
      >
        <FaMoon className="fa-moon" />
        <FaSun className="fa-sun" />
        <div
          className="toggle-ball"
          onClick={handleClick}
          style={{
            transform: theme.state.darkMode
              ? "translateX(0)"
              : "translateX(24px)",
          }}
        />
      </label>
    </>
  );
};

export default Toggle;
