import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Skills.scss";

const Skills = () => {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const query = `*[_type == "experiences"] | order(publishedAt desc)`;
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      if (isMounted) {
        setExperiences(data);
      }
    });

    client.fetch(skillsQuery).then((data) => {
      if (isMounted) {
        setSkills(data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <h2 className="head-text">
        <span>Skills</span> & <span>Experiences</span>
      </h2>

      <div className="app__skills-container">
        <div className="app__skills-list">
          {skills.map((skill) => (
            <div className="app__skills-item app__flex" key={skill.name}>
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img
                  src={urlFor(skill.icon) + "?h=30"}
                  height={30}
                  alt={skill.name}
                />
              </div>
              <p className="p-text">{skill.name}</p>
            </div>
          ))}
        </div>
        <div className="app__skills-exp">
          {experiences.map((experience) => (
            <div className="app__skills-exp-item" key={experience.year}>
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <div className="app__skills-exp-works">
                {experience.works.map((work) => (
                  <React.Fragment key={work.name}>
                    <div
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                    >
                      <h3 className="bold-text">{work.name}</h3>
                      <p className="p-text">{work.company}</p>
                    </div>

                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </React.Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Skills, "app__skills"), "skills");
