import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { email, emails } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Footer.scss";

const Footer = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          setIsFormSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <h2 className="head-text" style={{ marginTop: "50px" }}>
        Get In Touch <span>With Me</span>
      </h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={email} alt="email" />
          <a href="mailto:adilabendahou98@gmail.com" className="p-text">
            adilabendahou98@gmail.com
          </a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <form
          ref={form}
          onSubmit={sendEmail}
          className="app__footer-form app__flex"
        >
          <div className="app__flex app_row">
            <input
              required
              className="p-text"
              type="text"
              placeholder="Your Subject"
              name="user_subject"
            />

            <input
              required
              className="p-text"
              type="text"
              placeholder="Your Name"
              name="user_name"
            />
          </div>
          <div className="app__flex">
            <input
              required
              className="p-text"
              type="email"
              placeholder="Your Email"
              name="user_email"
            />
          </div>
          <div>
            <textarea
              required
              className="p-text"
              placeholder="Your Message"
              name="message"
            />
          </div>
          <div className="form__btn-container">
            <button type="submit" className="form__btn p-text">
              {!loading ? "Send Message" : "Sending..."}
            </button>
          </div>
        </form>
      ) : (
        <div className="app__thanks app__flex">
          <h3 className="head-text">Thank you for getting in touch!</h3>
          <img className="app__footer-img" src={emails} alt="contact" />
        </div>
      )}
    </>
  );
};

export default AppWrap(MotionWrap(Footer, "app__footer"), "contact");
