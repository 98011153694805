import React, { useState, useEffect } from "react";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";
import { urlFor, client } from "../../client";

const About = () => {
  const [abouts, setAbouts] = useState([]);
  const [about, setAbout] = useState([{ description: "" }]);

  useEffect(() => {
    let isMounted = true;

    const query = '*[_type == "abouts"]';
    const desc = '*[_type == "about"]';

    client.fetch(query).then((data) => {
      if (isMounted) {
        setAbouts(data);
      }
    });

    client.fetch(desc).then((data) => {
      if (isMounted) {
        setAbout(data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <h2 className="head-text">
        About <span>Me</span>
      </h2>
      {about && <p className="content-text">{about[0].description}</p>}

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <div className="app__profile-item" key={about.title + index}>
            <img
              src={urlFor(about.imgUrl) + "?h=250"}
              alt={about.title}
              height={250}
            />
            <h2 className="bold-text" style={{ marginTop: 20 }}>
              {about.title}
            </h2>
          </div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(About, "app__about"), "about");
