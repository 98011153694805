import React, { useEffect, useState } from "react";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { client } from "../client";

const SocialMedia = () => {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const query = '*[_type == "socials"]';

    client.fetch(query).then((data) => {
      if (isMounted) {
        setInfo(data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="app__social">
      {info[1]?.link && (
        <div>
          <a
            href={info[1]?.link}
            aria-label="Linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin />
          </a>
        </div>
      )}
      {info[0]?.link && (
        <div>
          <a
            href={info[0]?.link}
            aria-label="Github"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub />
          </a>
        </div>
      )}

      {info[2]?.link && (
        <div>
          <a
            href={info[2]?.link}
            aria-label="Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter />
          </a>
        </div>
      )}
    </div>
  );
};
export default SocialMedia;
