import { createContext, useEffect, useReducer } from "react";

export const ThemeContext = createContext();

const INITIAL_STATE = {
  darkMode: JSON.parse(localStorage.getItem("darkMode")) || false,
};

const themeReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE":
      return { darkMode: !state.darkMode };
    default:
      return state;
  }
};

export const ThemeProvider = (props) => {
  const [state, dispatch] = useReducer(themeReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(!state.darkMode));
  }, [state.darkMode]);

  useEffect(() => {
    const savedDarkMode = JSON.parse(localStorage.getItem("darkMode"));
    if (savedDarkMode !== null) {
      dispatch({ type: "TOGGLE" });
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
