import React, { useEffect, useState } from "react";
import { AppWrap } from "../../wrapper";
import { urlFor, client, getUrlFromId } from "../../client";
import { HiDownload } from "@react-icons/all-files/hi/HiDownload";

import "./Header.scss";

const Header = () => {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const query = '*[_type == "hero"]';
    client.fetch(query).then((data) => {
      if (isMounted) {
        setInfo(data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="app__header">
      {info[0] && (
        <>
          <div className="app__header-info">
            <div className="app__header-wrapper">
              <h2 className="app__header-intro">{info[0]?.intro}</h2>
              <h1 className="app__header-name">{info[0]?.name}</h1>
              <div className="app__header-title">
                <div className="app__header-title-wrapper">
                  <div className="app__header-title-item">
                    Software Engineer
                  </div>
                </div>
              </div>
              <p className="app__header-desc">{info[0]?.description}</p>

              <div className="app_header-btn-container">
                <a
                  href={`${getUrlFromId(
                    info[0]?.resume?.asset?._ref
                  )}?dl=RESUME_AADILA_BENDAHOU.pdf`}
                  className="app__header-button"
                  download
                >
                  Resume
                  <HiDownload />
                </a>
              </div>
            </div>
          </div>
          <div className="app__header-img ">
            <img src={urlFor(info[0]?.imgUrl)} alt="Profile" width={500} />
          </div>
        </>
      )}
    </div>
  );
};

export default AppWrap(Header, "home", "app__primarybg");
