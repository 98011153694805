import React, { useContext, useState } from "react";
import { logoDarkMode, logo } from "../../constants";
import "./Navbar.scss";
import { useEffect } from "react";
import Toggle from "../../toggle/Toggle";
import { ThemeContext } from "../../context/ThemeContext";

import { HiMenuAlt4 } from "@react-icons/all-files/hi/HiMenuAlt4";
import { HiX } from "@react-icons/all-files/hi/HiX";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const [navColor, setnavColor] = useState("transparent");

  const listenScrollEvent = () => {
    window.scrollY > 10
      ? setnavColor("rgba(255, 255, 255, 0.25)")
      : setnavColor("transparent");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <nav
      className="app__navbar"
      style={{
        backgroundColor: navColor,
      }}
    >
      <div className="app__navbar-logo">
        <a href="#home">
          <img src={darkMode ? logoDarkMode : logo} alt="logo" width={90} />
        </a>
      </div>
      <ul className="app__navbar-links">
        {["home", "about", "work", "skills", "testimonial", "contact"].map(
          (item) => (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div />
              <a href={`#${item}`}>{item}</a>
            </li>
          )
        )}
      </ul>

      <Toggle />

      <div className={`app__navbar-menu`}>
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <div className={`slide-in ${!toggle && "slide-out"}`}>
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {[
                "home",
                "about",
                "work",
                "skills",
                "testimonial",
                "contact",
              ].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
